import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"mt-5",attrs:{"flat":"","width":"550"}},[_c(VCardTitle,{staticClass:"justify-center text-h5 font-weight-bold"},[_vm._v(" TELEPRESENCE ")]),_c(VDivider),_c(VCardText,{staticClass:"font-weight-bold pb-0 text-h6"},[_vm._v("What is Telepresence?")]),_c(VCardText,{staticClass:"black--text pl-10"},[_vm._v(" Telepresence is a video communication tool allowing users to connect remotely with court or county officials. ")]),_c(VCardText,{staticClass:"font-weight-bold pb-0 text-h6"},[_vm._v("How Does Telepresence Operate?")]),_c(VCardText,{staticClass:"black--text pl-10"},[_vm._v(" 1) Please make sure the designated tab remains open (preferably in the Chrome browser). It doesn't always need to be the active tab. "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" 2) Monitor your availability status, located at the top right of the application. If it's green, you're ready. Change the status to \""),_c('strong',[_vm._v("Busy")]),_vm._v("\" or \""),_c('strong',[_vm._v("Unavailable")]),_vm._v("\" if you can't take a call at the moment. ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }