<template>
    <v-row class="fill-height" no-gutters>
        <v-col cols="12" class="d-flex align-center justify-center">
            <v-card flat width="550" class="mt-5">
                <v-card-title class="justify-center text-h5 font-weight-bold">
                    TELEPRESENCE
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="font-weight-bold pb-0 text-h6">What is Telepresence?</v-card-text>
                <v-card-text class="black--text pl-10">
                    Telepresence is a video communication tool allowing users to connect remotely with court or county
                    officials.
                </v-card-text>
                <v-card-text class="font-weight-bold pb-0 text-h6">How Does Telepresence Operate?</v-card-text>
                <v-card-text class="black--text pl-10">
                    1) Please make sure the designated tab remains open (preferably in the Chrome browser). It doesn't
                    always need to be the active tab.
                    <br> <br>
                    2) Monitor your availability status, located at the top right of the application. If it's green, you're
                    ready. Change the status to
                    "<strong>Busy</strong>" or "<strong>Unavailable</strong>" if you can't take a call at the moment.
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "telepresenceNotes",
}
</script>